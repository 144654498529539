import pkceChallenge from "pkce-challenge";
import {REACT_APP_LINK, SSO_URL} from "modules/utils/Constant";
import {Storage} from "modules/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export * from "./Api";
export * from "./hooks";
export * from "./User";
export * from "./Storage";
export * from "./Constant";
export * from "./currency";
export * from "./social_share";
export * from "./enums";
export * from "./SecretGate";

export function createMarkup(html: string) {
	return {__html: html};
}
const {code_verifier, code_challenge} = pkceChallenge(128);
const client_id = "b3061695-24e5-4ce6-881e-ec74b13a4d81";

export const getLoginLink = () => {
	const saved_code_verifier = Storage.GET("code_verifier");
	if (window.location.search.indexOf("?code=") !== -1 && saved_code_verifier) {
		Storage.SET("saved_code_verifier", saved_code_verifier);
	} else {
		Storage.REMOVE("saved_code_verifier");
	}
	Storage.SET("code_verifier", code_verifier);
	Storage.SET("code_challenge", code_challenge);
	return `${
		SSO_URL || ""
	}login/authorize?code_challenge=${code_challenge}&code_challenge_method=S256&language=en&client_id=${client_id}&redirect_uri=${REACT_APP_LINK}login/&response_type=code&_ga=`;
};

export const getRegisterLink = () => {
	Storage.SET("code_verifier", code_verifier);
	Storage.SET("code_challenge", code_challenge);
	return `${SSO_URL}register?code_challenge=${code_challenge}&code_challenge_method=S256&language=en&client_id=${client_id}&redirect_uri=${REACT_APP_LINK}login/&response_type=code&_ga=`;
};

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;
